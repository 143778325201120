.hofitem {
    padding: 5px;
    margin: 5px;
  }
  
  .hofpict {  
    max-width: 64px;
    max-height: 64px;  
  }
  
  .hofpictdiv {
    float: left;
    width: 64px;
    height: 100%;
    margin: 2px;
    margin-right: 10px;
  }

  .bodypantheon {
    background: linear-gradient(rgba(47, 23, 15, 0.65), rgba(47, 23, 15, 0.65)), url("img/pantheonbg.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }