.intro {
    position: relative;
  }

  .slideshowbeta {
    width: 100%;
  }

  .smallfloatingpict {
    width: 100%;
  }

  .smallfloatingpictleft {
    width: 100%;
  }
  
  @media (min-width: 992px) {

    .slideshowbeta {
      float: right;
      max-width: 600px;
    }

    .smallfloatingpict {
      float: right;
      max-width: 300px;
      margin-top: -25px;
      margin-left: 10px;
    }

    .smallfloatingpictleft {
      float: left;
      max-width: 300px;
      margin-top: -25px;
      margin-right: 10px;
    }

    .intro .intro-img {
      width: 75%;
      float: right;
    }
    .intro .intro-text {
      left: 0;
      width: 90%;
      margin-top: 3rem;
      position: absolute;
    }
    .intro .intro-text .intro-button {
      width: 100%;
      left: 0;
      position: absolute;
      bottom: -2rem;
    }
  }
  
  @media (min-width: 1200px) {
    .intro .intro-text {
      width: 70%;
    }
  }

  .section-heading {
    text-transform: uppercase;
  }
  
  .section-heading .section-heading-upper {
    display: block;
    font-size: 1rem;
    font-weight: 800;
  }
  
  .section-heading .section-heading-lower {
    display: block;
    font-size: 2rem;
    font-weight: 100;
  }

  .cta {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: rgba(221,199,125,0.7);
  }
  
  .cta .cta-inner {
    position: relative;
    padding: 3rem;
    margin: 0.5rem;
    background-color: rgba(249, 236, 195, 1);
    border: 0.25rem solid rgba(249, 236, 195, 1);
  }

  .iconDL {
      height: 48px;
      width: 48px;
  }

  .singleitem {
    padding: 3em;
    margin: 0.5em;
  }
  