body {
  font-family: 'Lora';
  background: linear-gradient(rgba(47, 23, 15, 0.65), rgba(47, 23, 15, 0.65)), url("img/bg_tall.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.maincontent {
  width: 100%;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.col-12 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

@media (min-width: 1024px) {
  body {
    background: linear-gradient(rgba(47, 23, 15, 0.65), rgba(47, 23, 15, 0.65)), url("img/bg.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-12 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .maincontent {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway';
}

p {
  line-height: 1.75;
}

a {
  color: #925c17 !important;
}

.page-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.millenaire-header {
  text-transform: uppercase;
  display: block;
  font-size: 2rem;
  font-weight: 100;
  text-align: left;
}

.millenaire-subheader {
  text-transform: uppercase;
  display: block;
  font-size: 1.5rem;
  font-weight: 100;
  text-align: left;
}

.millenaire-subheader-responsive {
  text-transform: uppercase;
  display: block;
  font-size: 1.1rem;
  font-weight: 100;
  text-align: left;
}

@media (min-width: 576px) {
  .container {
    max-width: 1140px !important;
  }
}

@media (min-width: 992px) {
  .millenaire-subheader-responsive {
    font-size: 1.5rem;
  }
}

.bg-faded {
  background-color: rgba(255, 255, 255, 0.85);
}

.bg-yellow {
  background-color: rgba(249, 236, 195, 1);
}

.btn {
  box-shadow: 0px 3px 3px 0px rgba(33, 37, 41, 0.1);
}

.btn-primary {
  background-color: #e6a756 !important;
  border-color: #e6a756 !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #df902a;
  border-color: #df902a;
}

.btn-delete {
  background-color: #d6281b !important;
  border-color: #d6281b !important;
}

.lightbeigebg {
	background-color: rgba(249, 236, 195, 1);
}

.vertical-align {
	display:flex;
	align-items:center;
}

.maincontentzone {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.activeicon {
  width: 32px;
  height: 32px;
  color: #df902a;
}

.buttonicon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  color: #ffffff;
  vertical-align: middle;
}

.deleteicon {
  width: 32px;
  height: 32px;
  color: white;
}

.dropdown-menu li {
  clear: both;
  color: #333;
  display: block;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 3px 20px;
  white-space: nowrap;
}

.ads {
  margin: 5px;
}

.sideads {
  display: none;
}

.formcheck {
  color: rgb(131, 131, 131);
  float: right;
  margin-right: -30px;
  font-size: 1.2em;
}

.formcheck_valid {
  color: green;
}

@media (min-width: 1460px) {
  .sideads {
    display: block !important;
  }
}