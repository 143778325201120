.pagination li {
    border-radius: 5px;
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }

  .pagination {
    display: inline-block;
    margin-bottom: 0;
  }
  
  .pagination .active {
    background-color: #df902a;
  }
  
  .pagination li:hover:not(.active) {background-color: white;}