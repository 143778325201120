.cardicon {
    width: 16px;
    height: 16px;
    color: #b66e10;
    margin-right:5px;
  }

  .dlicon {
    width: 64px;
    height: 64px;
    color: #b66e10;
  }

  .cardattribute {
      padding:5px;
  }

  .libraryformlabel {
    width:300px;
  }