.header-title {
    font-size: 2rem;
    font-weight: 100;
    line-height: 2rem;
  }

  .mainlogo {    
    padding: 10px;
  }



.topheaderbutton {
    margin: 5px;
  }
  
  @media (max-width: 992px) {
    .mainlogo {
      width: 100%;
    }
    
    .topheaderbutton {
      background-color: rgb(221, 198, 123);
    }
  }
  
  @media (min-width: 992px) {
    .mainlogo {
      max-width: 480px;
    }
  
    .topheaderbutton {
      background-color: rgba(249, 236, 195, 1);
    }
  }

  .external-items {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .site-heading {
    margin-top: 5rem;
    margin-bottom: 5rem;
    line-height: 1;
  }
  
  .site-heading .site-heading-upper {
    display: block;
    font-size: 2rem;
    font-weight: 800;
  }
  
  .site-heading .site-heading-lower {
    font-size: 2rem;
    font-weight: 100;
    line-height: 2rem;
  }

  .pagenavitem {
    color: #e6a756;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
  }
  
  @media (min-width: 992px) {
    .pagenavitem {
      font-size: 0.9rem;
    }
  }

  .footer {
    background-color: rgba(47, 23, 15, 0.9);
  }

  .text-faded {
    color: rgba(255, 255, 255, 0.3);
  }