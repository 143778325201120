.advancement {
    padding: 5px;
    margin: 5px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    min-height: 78px;
    display: flex;
    align-items: center;
  }
  
  .advancementcomplete {
    background-color: rgba(255, 204, 0, 0.5);
  }
  
  .advancementpict {  
    width: 64px;
    height: 64px;  
  }
  
  .advancementpictdiv {
    float: left;
    width: 64px;
    height: 64px;  
    height: 100%;
    margin: 2px;
    margin-right: 10px;
  }
  
  .advancementpercent {
    font-size:xx-large;
    font-weight:bold;
    margin-left: auto;
  }
  
  .advancementprogress {
    background-color: rgba(255, 255, 255, 0.7);
    position:absolute; 
    left:0;
    top:0;
    height:100%;
    z-index:-1
  }
  
  .advancementprogressall {
    background-color: rgba(255, 255, 255, 0.3);
    position:absolute; 
    left:0;
    top:0;
    height:100%;
    z-index:-1
  }